/* eslint-disable */
import React from 'react';
import { BarChart2, Percent, PieChart as PieChartIcon, TrendingUp } from 'react-feather';
import styled from 'styled-components/macro';
import { Paper } from '@material-ui/core';
import { DEFINITIONS, ENGAGEMENT_TYPES } from '../../../../storage/constants';
import { addCommas } from '../../../../storage/helpers';
import ProgBarMini from './ProgBarMini';
import ExtraInfo from '../../../../components/ExtraInfo';
import HighlightIncrease from '../reuseableComponents/HighlightIncrease';
import ExplanationTooltip from './AlertAccordion/ExplanationTooltip';
import { Typography, fadeInRight } from '../../styles';

const Box = styled(Paper)`
  flex: 0.25;
  background-color: white;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  p {
    font-size: 14px;
    font-weight: 500;
    color: darkgrey;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
`;

const BoldText = styled.span`
  font-size: 40px;
  font-weight: 800;
  margin-top: 0;
  color: #337ab7;
  animation: 300ms ${fadeInRight} ease-out;

  @media (max-width: 600px) {
    font-size: 30px;
  }
`;

const Container = styled.div`
  height: 180px;
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-template-areas: 'box1 box2 box3 box4';

  @media (max-width: 800px) {
    height: 360px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'box1 box1 box2 box2'
      'box3 box3 box4 box4';
  }
`;

const InnerBox = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 12px;
  width: 100%;
  height: 100%;
`;

const calcPassRate = (subs, comps) => Math.floor((comps / subs) * 100);

const getOverallStatsBySubType = (submissonsData, subType) => {
  let passRate;
  let totalCompletions;
  let totalSubmissions;
  let completionsLast14;
  let submissionsLast14;

  if (subType === ENGAGEMENT_TYPES.ASSIGNED) {
    totalCompletions = submissonsData.assigned_completions;

    totalSubmissions = submissonsData.assigned_submissions;
    passRate = calcPassRate(
      submissonsData.assigned_submissions,
      submissonsData.assigned_completions
    );
    completionsLast14 = submissonsData.recent_submissions.filter(
      d => d.assigned && d.passed
    ).length;
    submissionsLast14 = submissonsData.recent_submissions.filter(d => d.assigned).length;
  }

  if (subType === ENGAGEMENT_TYPES.EXTRACREDIT) {
    totalCompletions = submissonsData.extra_credit_completions;
    totalSubmissions = submissonsData.extra_credit_submissions;
    passRate = calcPassRate(
      submissonsData.extra_credit_submissions,
      submissonsData.extra_credit_completions
    );
    completionsLast14 = submissonsData.recent_submissions.filter(
      d => !d.assigned && d.passed
    ).length;
    submissionsLast14 = submissonsData.recent_submissions.filter(d => !d.assigned).length;
  }

  if (subType === ENGAGEMENT_TYPES.ALL) {
    totalCompletions =
      submissonsData.extra_credit_completions + submissonsData.assigned_completions;
    totalSubmissions =
      submissonsData.assigned_submissions + submissonsData.extra_credit_submissions;
    passRate = calcPassRate(
      submissonsData.assigned_submissions + submissonsData.extra_credit_submissions,
      submissonsData.extra_credit_completions + submissonsData.assigned_completions
    );
    completionsLast14 = submissonsData.recent_submissions.filter(d => d.passed).length;
    submissionsLast14 = submissonsData.recent_submissions.length;
  }

  return {
    passRate,
    totalCompletions,
    totalSubmissions,
    completionsLast14,
    submissionsLast14,
  };
};

const StatBoxes = ({ submissionsType, submissionsData, companyData }) => {
  const { passRate, totalCompletions, totalSubmissions, completionsLast14, submissionsLast14 } =
    getOverallStatsBySubType(submissionsData, submissionsType);

  return (
    <Container>
      <Box style={{ gridArea: 'box1' }}>
        <InnerBox>
          <BarChart2
            style={{
              height: 28,
              width: 28,
              color: 'black',
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <p>Submissions (All Time)</p>
            <ExtraInfo text={DEFINITIONS.submissions} />
          </div>
          <BoldText>{addCommas(totalSubmissions)}</BoldText>
        </InnerBox>
        <HighlightIncrease subType={submissionsType}>
          <span>
            <strong style={{ fontWeight: '900', marginRight: 2 }}>
              + {addCommas(submissionsLast14)}{' '}
            </strong>
            last 14 days
          </span>
        </HighlightIncrease>
      </Box>
      <Box style={{ gridArea: 'box2' }}>
        <InnerBox>
          <PieChartIcon
            style={{
              height: 28,
              width: 28,
              color: 'black',
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <p>Completions (All Time)</p>
            <ExtraInfo text={DEFINITIONS.completions} />
          </div>
          <BoldText>{addCommas(totalCompletions)}</BoldText>
        </InnerBox>
        <HighlightIncrease subType={submissionsType}>
          <span>
            <strong style={{ fontWeight: '900', marginRight: 2 }}>
              + {addCommas(completionsLast14)}
            </strong>
            last 14 days
          </span>
        </HighlightIncrease>
      </Box>
      <Box style={{ gridArea: 'box3' }}>
        <InnerBox>
          <Percent
            style={{
              height: 28,
              width: 28,
              color: 'black',
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <p>Pass Rate</p>
            <ExtraInfo text={DEFINITIONS.passRate} />
          </div>
          <BoldText>{passRate ? `${passRate}%` : 'N/A'}</BoldText>
        </InnerBox>
        <div style={{ height: 20 }} />
      </Box>
      <Box style={{ gridArea: 'box4' }}>
        <InnerBox>
          <TrendingUp
            style={{
              height: 28,
              width: 28,
              color: 'black',
              marginBottom: 4,
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <p>Progress</p>
            <ExtraInfo text={DEFINITIONS.progress} />
          </div>
          <BoldText>
            {submissionsType === ENGAGEMENT_TYPES.EXTRACREDIT
              ? 'N/A'
              : `${Math.floor(companyData?.progress_rate)}%`}
          </BoldText>
        </InnerBox>
        {submissionsType !== ENGAGEMENT_TYPES.EXTRACREDIT && companyData?.progress_rate > 0 ? (
          <ProgBarMini progress={companyData?.progress_rate} />
        ) : (
          <div style={{ height: 20 }} />
        )}
      </Box>
    </Container>
  );
};

export default StatBoxes;
