/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CustomDatatableV2 } from "../styles";
import { getSrcTwo, makeDefaultTableOptions } from "../../../storage/helpers";
import ShowCompanyWithImage from "../../../components/ShowCompanyWithImage";
import TableTitle from "../../../components/Table/TableTitle";
import { CheckCircle, Rss, Search, XCircle } from "react-feather";
import ActionButtonForTables from "../../../components/Table/ActionButtonForTables";
import API from "../../../axios/instances/API";
import { Modal, LinearProgress, useMediaQuery } from "@material-ui/core";
import ReviewModal from "../marketplace/review";
import CustomDataTableIcons from "../../../components/Table/CustomDataTableIcons";

const SuggestedQuizzesTable = ({
  suggestedQuizzes,
  setSnackbarText,
  mutate,
  hasPermissionsToCreateModule,
}) => {
  const [reviewModalInfo, setReviewModalInfo] = useState(null);
  const [actionPending, setActionPending] = useState(false);
  const isUnder600Px = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const tableData = suggestedQuizzes.map((suggested) => ({
    id: suggested.id,
    module: suggested.quiz__name,
    company: suggested.quiz__company__name,
    review: suggested.id,
    accept: suggested.id,
    decline: suggested.id,
    moduleImage: suggested?.quiz__image || null,
    companyImage: suggested.quiz__company__logo || null,
  }));

  const options = makeDefaultTableOptions("suggested-modules");
  options.rowsPerPage = 5;
  options.textLabels = {
    body: {
      noMatch:
        "No new modules are suggested to your team. Head to the marketplace to deploy new modules to your team!",
    },
  };

  if (isUnder600Px) {
    options.rowsPerPageOptions = [];
    options.jumpToPage = false;
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "module",
      label: "Module",
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <img
              src={getSrcTwo(tableMeta?.rowData[6])}
              style={{
                border: "1px solid #eeeeee",
                height: 40,
                width: 40,
                borderRadius: 6,
                objectFit: "contain",
              }}
            />
            <span style={{ fontWeight: "500" }}>{value}</span>
          </div>
        ),
      },
    },
    {
      name: "company",
      label: "Vendor",
      options: {
        filter: true,
        sort: true,
        display: true,

        customBodyRender: (value, tableMeta) => (
          <ShowCompanyWithImage
            value={value}
            logo={getSrcTwo(tableMeta.rowData[7])}
            isWide
          />
        ),
      },
    },
    {
      name: "review",
      label: "Review",
      options: {
        customBodyRender: (value) => (
          <ActionButtonForTables
            title="Review Module"
            Icon={Search}
            handleClick={() => handleReview(value)}
            // disabled={!hasPermissionsToCreateModule}
          />
        ),
      },
    },
    {
      name: "accept",
      label: "Accept",
      options: {
        customBodyRender: (value) => (
          <ActionButtonForTables
            title="Accept Module"
            Icon={CheckCircle}
            handleClick={() => handleAccept(value)}
            disabled={!hasPermissionsToCreateModule}
          />
        ),
      },
    },
    {
      name: "decline",
      label: "Decline",
      options: {
        customBodyRender: (value) => (
          <ActionButtonForTables
            title="Decline Module"
            Icon={XCircle}
            handleClick={() => handleReject(value)}
            disabled={!hasPermissionsToCreateModule}
          />
        ),
      },
    },
    {
      name: "moduleImage",
      label: " ",
      options: {
        sort: false,
        filter: false,
        download: false,
        empty: true,
        print: false,
        display: false,
      },
    },
    {
      name: "companyImage",
      label: "",
      options: {
        display: false,
        filter: false,
        print: false,
        sort: false,
      },
    },
  ];

  const handleAcceptError = (error) => {
    if (error?.response?.data?.error?.details) {
      return `${error?.response?.data?.error?.details} error`;
    }

    return "Failed to add module to your library error";
  };

  const handleAccept = async (id) => {
    setActionPending(true);
    try {
      await API.post("/dashboard/quiz/pushed/details/", {
        id,
      });
      mutate();
      setActionPending(false);
      setSnackbarText("Successfully added module to your library success");
    } catch (error) {
      const errorMsg = handleAcceptError(error);
      setActionPending(false);
      setSnackbarText(errorMsg);
    }
  };

  const handleReview = async (id) => {
    setReviewModalInfo({ id, exists: false });
  };

  const handleReject = async (id) => {
    setActionPending(true);
    try {
      await API.delete("/dashboard/quiz/pushed/details/", {
        data: { id },
      });
      await mutate();
      setActionPending(false);
      setSnackbarText("Rejected suggested module warning");
    } catch (error) {
      setActionPending(false);
      setSnackbarText("Failed to reject module.. please try again later error");
    }
  };

  return (
    <>
      <CustomDatatableV2
        title={<TableTitle title="Suggested Modules" Icon={Rss} />}
        data={tableData}
        columns={columns}
        options={options}
        components={{
          icons: CustomDataTableIcons,
        }}
      />
      <div>{actionPending ? <LinearProgress color="secondary" /> : null}</div>
      <Modal
        open={Boolean(reviewModalInfo)}
        onClose={() => setReviewModalInfo(null)}
        aria-labelledby="Review Suggested Module"
        aria-describedby="Review Suggested Module"
      >
        <div>
          <ReviewModal
            reviewModalInfo={reviewModalInfo}
            setReviewModalInfo={setReviewModalInfo}
            setSnackbarText={setSnackbarText}
            isSuggested
          />
        </div>
      </Modal>
    </>
  );
};

export default SuggestedQuizzesTable;

SuggestedQuizzesTable.propTypes = {
  suggestedQuizzes: PropTypes.array.isRequired,
  mutate: PropTypes.func.isRequired,
  setSnackbarText: PropTypes.func.isRequired,
};
