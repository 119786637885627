/* eslint-disable */
import React, { useState } from "react";
import { Paper, Modal, Button } from "@material-ui/core";
import styled from "styled-components/macro";
import ChangeMemberLocation from "./ChangeMemberLocation";
import { User, Shuffle, Plus, Trash2 } from "react-feather";
import SnackbarWrapper from "../../../../components/SnackbarWrapper";
import ConfirmDeleteTeamMember from "./ConfirmDeleteTeamMember";
import Promote from "./Promote";

const Wrapper = styled(Paper)`
  border-radius: 6px;
  min-width: 280px;
  min-height: 151px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 4px;
`;

const CustomButton = styled(Button)`
  height: 38px;
  width: 58px;
  min-height: 38px;
  min-width: 58px;
  border-radius: 6px;
  postion: relative;
  background-color: #eeeeee;

  svg {
    color: slategray;
    height: 16px;
    width: 16px;
  }

  &:hover {
    svg {
      color: black;
    }
  }
`;

const AbsoluteText = styled.span`
  position: absolute;
  bottom: -14px;
  left: 8px;
  font-size: 10px;
  color: darkgrey;
  line-height: 1;
`;

const UserHero = ({ data, mutate, isManager, readOnly }) => {
  const [deleteInfo, setDeleteInfo] = useState(null);
  const [promoteModalOpen, setPromoteModalOpen] = useState(false);
  const [changeLocationOpen, setChangeLocationOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const { user, team_member: teamMember } = data || {};

  const fullName = `${user?.first_name} ${user?.last_name}`;

  return (
    <>
      <Wrapper>
        <div
          style={{
            height: 48,
            width: 48,
            borderRadius: 12,
            display: "flex",
            background: "#eeeeee88",
            border: "1px solid lightgray",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <User color="black" height={24} width={24} />
        </div>
        <p style={{ fontSize: 20, fontWeight: "700", marginTop: 8 }}>
          {fullName}
        </p>
        <p>{user?.username}</p>

        <div
          style={{
            width: "100%",

            minHeight: 60,
            marginTop: 16,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <CustomButton
            disabled={isManager || readOnly}
            onClick={() => setPromoteModalOpen(true)}
          >
            <Plus />
            <AbsoluteText>Promote</AbsoluteText>
          </CustomButton>
          <CustomButton
            disabled={readOnly || isManager}
            onClick={() => setChangeLocationOpen(true)}
          >
            <Shuffle />
            <AbsoluteText>Location</AbsoluteText>
          </CustomButton>
          <CustomButton
            disabled={readOnly || isManager}
            onClick={() => {
              setDeleteInfo({
                id: teamMember.id,
                name: fullName,
              });
            }}
          >
            <Trash2 />
            <AbsoluteText>Remove</AbsoluteText>
          </CustomButton>
        </div>
      </Wrapper>
      <Modal
        open={Boolean(deleteInfo)}
        onClose={() => setDeleteInfo(null)}
        aria-labelledby="Delete a location"
        aria-describedby="modal to delete a location"
      >
        <div>
          <ConfirmDeleteTeamMember
            deleteInfo={deleteInfo}
            setDeleteInfo={setDeleteInfo}
            endpoint={
              deleteInfo ? `dashboard/teams/details/?id=${deleteInfo.id}` : null
            }
          />
        </div>
      </Modal>
      <Modal
        open={promoteModalOpen}
        onClose={() => setPromoteModalOpen(false)}
        aria-labelledby="Give Team Member Manger/Admin Privilege"
        aria-describedby="Give Team Member Manger/Admin Privilege"
      >
        <div>
          <Promote
            setPromoteModalOpen={setPromoteModalOpen}
            setSnackbarText={setSnackbarText}
            promoteInfo={{
              fullName,
              id: teamMember.id,
              currentLevel: user.job,
              currentLocation: teamMember.location,
            }}
          />
        </div>
      </Modal>
      <Modal
        open={changeLocationOpen}
        onClose={() => setChangeLocationOpen(false)}
        aria-labelledby="Change Team Member Location"
        aria-describedby="Modal to change team member location"
      >
        <div>
          <ChangeMemberLocation
            memberName={fullName}
            memberId={teamMember.id}
            currentLocation={teamMember.location}
            setChangeLocationOpen={setChangeLocationOpen}
            setSnackbarText={setSnackbarText}
            mutate={mutate}
            details={data}
          />
        </div>
      </Modal>
      <SnackbarWrapper
        snackbarText={snackbarText}
        setSnackbarText={setSnackbarText}
      />
    </>
  );
};

export default UserHero;
