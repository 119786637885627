/* eslint-disable */
import React, { useEffect, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { IconButton, Paper } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { subMonths, addDays } from "date-fns";
import { X } from "react-feather";
import styled, { keyframes } from "styled-components/macro";
import API from "../../../../../axios/instances/API";
import { formatDateValue, parseDateChange } from "../../utils/utils";
import { getClientTimezone } from "../../../../../storage/helpers";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const CustomDatePicker = styled(DatePicker)`
  flex: 0.25;
  background-color: white;
`;

const Wrapper = styled(Paper)`
  height: 270px;
  border-radius: 4px;
  width: 100%;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px;
`;

const GenerateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  border-radius: 2px;
  width: fit-content;
`;

const GreyTimes = styled(X)`
  color: ${(props) => props.theme.palette.grey[400]};
  height: 16px;
  width: 16px;
`;

const Column = styled.div`
  flex: 0.25;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;
  background: #eeeeee60;
  margin: 1px;

  span {
    font-size: 20px;
    color: black;
    font-weight: 800;
  }

  p {
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 800px) {
    span {
      font-size: 16px;
    }

    p {
      font-size: 11px;
    }
  }
`;

const UpperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #eeeeee;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const RowLabel = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: black;
`;

const NoValueDiv = styled.div`
  height: 96%;
  width: 96%;
  border-radius: 2px;
  background: #eeeeee80;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    animation: 400ms ${fadeIn} ease-out;
  }
`;

const NoValueIndicator = () => (
  <NoValueDiv>
    <span style={{ fontSize: 16, fontWeight: "300", color: "darkgrey" }}>
      - - -
    </span>
  </NoValueDiv>
);

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: fit-content;
  align-self: flex-end;
  padding: 8px 0px;
  padding: 8px;
  border-radius: 3px;
`;
const { timezoneEncoded } = getClientTimezone();

const BASE_ENDPOINT = `/dashboard/analytics/retail-reports/company-submissions-count/?timezone=${timezoneEncoded}`;

const TODAY = new Date();

const EngagementByDateSelect = () => {
  const INITIAL_END_DATE = parseDateChange(addDays(new Date(), 1));
  const INITIAL_START_DATE = parseDateChange(subMonths(TODAY, 1));

  const [startDate, setStartDate] = useState(INITIAL_START_DATE);
  const [endDate, setEndDate] = useState(INITIAL_END_DATE);
  const [data, setData] = useState(null);
  const [updating, setUpdating] = useState(false);

  const getReport = async () => {
    setUpdating(true);
    try {
      const { data: apiData } = await API.get(
        `${BASE_ENDPOINT}&start=${startDate}&end=${endDate}`
      );
      setData(apiData);
      setUpdating(false);
    } catch (error) {
      setUpdating(false);
    }
  };

  const removeReportData = () => setData(null);

  useEffect(() => {
    if (!startDate || !endDate) {
      return removeReportData();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!startDate || !endDate) {
      return undefined;
    }

    getReport();
  }, [startDate, endDate]);

  const handleClr = (e, dateInput) => {
    e.stopPropagation();
    if (dateInput === "start") {
      setStartDate(null);
    } else {
      setEndDate(null);
    }
  };

  return (
    <Wrapper>
      <UpperContainer>
        <p
          style={{
            fontWeight: "600",
            fontSize: 16,
            marginLeft: 12,
          }}
        >
          Date Specific Engagement
        </p>
        <GenerateContainer>
          <DatesWrapper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div>
                <CustomDatePicker
                  size="small"
                  disableToolbar
                  autoOk
                  fullWidth
                  placeholder="Start Date"
                  inputVariant="outlined"
                  maxDate={formatDateValue(endDate)}
                  minDateMessage={null}
                  variant="inline"
                  format="yyyy-MM-dd"
                  value={formatDateValue(startDate)}
                  onChange={(date) => setStartDate(parseDateChange(date))}
                  style={{ width: 200, borderRadius: 4 }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={(e) => handleClr(e, "start")}
                        style={{ display: !startDate ? "none" : "flex" }}
                      >
                        {!startDate ? null : <GreyTimes />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
              <span>-</span>
              <div>
                <CustomDatePicker
                  size="small"
                  disableToolbar
                  autoOk
                  // disableFuture
                  style={{ width: 200, borderRadius: 4 }}
                  placeholder="End Date"
                  fullWidth
                  inputVariant="outlined"
                  minDate={formatDateValue(startDate)}
                  minDateMessage={null}
                  maxDate={addDays(new Date(), 1)}
                  variant="inline"
                  format="yyyy-MM-dd"
                  value={formatDateValue(endDate)}
                  onChange={(date) => setEndDate(parseDateChange(date))}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={(e) => handleClr(e, "end")}
                        style={{ display: !endDate ? "none" : "flex" }}
                      >
                        {!endDate ? null : <GreyTimes />}
                      </IconButton>
                    ),
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
            <div
              style={{
                width: "100%",
                marginTop: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            ></div>
          </DatesWrapper>
        </GenerateContainer>
      </UpperContainer>
      <div style={{ height: 2 }} />
      <div
        style={{
          width: "100%",
          background: "white",
          flex: 1,
          padding: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 4,
            flex: 1,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <div
            style={{
              flex: 0.2,
              width: "100%",
              flexBasis: 20,
              background: "#eeeeee40",
              borderRadius: 4,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Column
              style={{ flex: 0.25, background: "none", border: "none" }}
            ></Column>
            <Column style={{ border: "none", background: "#eeeeee" }}>
              <p style={{ color: "black", fontWeight: "500" }}>Assigned</p>
            </Column>
            <Column style={{ border: "none", background: "#eeeeee" }}>
              <p style={{ color: "black", fontWeight: "500" }}>Extra Credit</p>
            </Column>
            <Column style={{ background: "lightgray" }}>
              <p style={{ color: "black", fontWeight: "800" }}>TOTAL</p>
            </Column>
          </div>
          <div
            style={{
              flex: 0.4,
              width: "100%",
              // border: '1px solid #eeeeee',
              borderBottom: "none",

              display: "flex",
            }}
          >
            <Column
              style={{
                border: "none",
                borderRadius: 1,
                background: "#eeeeee40",
              }}
            >
              <RowLabel>Submissions</RowLabel>
            </Column>
            <Column>
              {updating ? (
                <NoValueDiv>
                  <NoValueIndicator />
                </NoValueDiv>
              ) : (
                <span style={{ color: "black" }}>
                  {data?.assigned_submissions}
                </span>
              )}
            </Column>
            <Column style={{}}>
              {updating ? (
                <NoValueDiv>
                  <NoValueIndicator />
                </NoValueDiv>
              ) : (
                <span style={{ color: "black" }}>
                  {data?.extra_credit_submissions}
                </span>
              )}
            </Column>
            <Column style={{ background: "#eeeeee" }}>
              {updating ? (
                <NoValueDiv>
                  <NoValueIndicator />
                </NoValueDiv>
              ) : (
                <span>
                  {data?.assigned_submissions +
                    data?.extra_credit_submissions || ""}
                </span>
              )}
            </Column>
          </div>
          <div
            style={{
              flex: 0.4,
              width: "100%",
              // border: '1px solid #eeeeee',
              display: "flex",
            }}
          >
            <Column
              style={{
                border: "none",
                background: "#eeeeee40",
              }}
            >
              <RowLabel>Completions</RowLabel>
            </Column>
            <Column>
              {updating ? (
                <NoValueDiv>
                  <NoValueIndicator />
                </NoValueDiv>
              ) : (
                <span style={{ color: "black" }}>
                  {data?.assigned_completions}
                </span>
              )}
            </Column>
            <Column>
              {updating ? (
                <NoValueDiv>
                  <NoValueIndicator />
                </NoValueDiv>
              ) : (
                <span style={{ color: "black" }}>
                  {data?.extra_credit_completions}
                </span>
              )}
            </Column>
            <Column style={{ background: "#eeeeee" }}>
              {updating ? (
                <NoValueDiv>
                  <NoValueIndicator />
                </NoValueDiv>
              ) : (
                <span style={{ fontWeight: "800" }}>
                  {data?.assigned_completions +
                    data?.extra_credit_completions || ""}
                </span>
              )}
            </Column>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default EngagementByDateSelect;
