/* eslint-disable */
import React, { useState } from "react";
import { Button, Paper } from "@material-ui/core";
import styled from "styled-components/macro";
import useQuizReview from "../../../../swr/hooks/Library/useQuizReview";
import { AlertTriangle } from "react-feather";
import API from "../../../../axios/instances/API";
import { Alert } from "@material-ui/lab";
import FunLoader from "../../../../components/Loaders/FunLoader";

const ModalWrapper = styled(Paper)`
  padding: 20px;
  width: calc(min(420px, 96vw));
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 280px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: scroll;
  border: 2px solid black;
`;

const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  padding: 4px 2px;
`;

const LaunchScheduledModModal = ({ handleClose, id, setSnackbarText }) => {
  const { details, isLoading } = useQuizReview(id);
  const [isLaunching, setIsLaunching] = useState(false);
  const [error, setError] = useState(false);

  if (isLoading) {
    return (
      <ModalWrapper>
        <span style={{ fontSize: 16, fontWeight: "600" }}>
          Launch Pending Module
        </span>
        <div style={{ height: 8 }} />
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 18,
          }}
        >
          <FunLoader />
        </div>
      </ModalWrapper>
    );
  }

  const handleLaunchPending = async () => {
    setIsLaunching(true);

    try {
      // await API.post("/dashboard/quiz/deployment/launch-scheduled-quiz/", {
      //   id,
      // });
      setSnackbarText("Module launched successfully 🚀 success");
      handleClose();
      // setIsLaunching(false);
    } catch (error) {
      // console.log(error);
      setError("Error launching this module.");
      setIsLaunching(false);
    }
  };

  return (
    <ModalWrapper>
      <span style={{ fontSize: 16, fontWeight: "600" }}>
        Launch Pending Module
      </span>
      <div style={{ height: 8 }} />

      <div
        style={{
          width: "100%",
          flex: 1,

          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FlexDiv>
          <span>Module:</span>
          <span style={{ fontSize: 13, fontWeight: "500" }}>
            {details?.name}
          </span>
        </FlexDiv>
        <FlexDiv>
          <span>State:</span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
            }}
          >
            <AlertTriangle color="orange" height={14} width={14} />
            <span style={{ fontSize: 13, fontWeight: "500" }}>
              Pending Release
            </span>
          </div>
        </FlexDiv>
        <FlexDiv>
          <span>Current Launch date:</span>
          <span style={{ fontSize: 13, fontWeight: "500" }}>
            {details?.startDate}
          </span>
        </FlexDiv>

        <div style={{ height: 20 }} />
        <span
          style={{
            fontSize: 12,
            color: "darkgrey",
          }}
        >
          If you want to launch this module live now, click launch module below.
        </span>
      </div>
      <div style={{ height: 6 }} />

      <Button
        variant="contained"
        color="secondary"
        style={{ marginTop: "auto", marginLeft: "auto" }}
        onClick={handleLaunchPending}
        disabled={isLaunching || error}
      >
        {isLaunching ? "Launcing Module.." : "🚀 Launch Module"}
      </Button>
      {error && (
        <>
          <div style={{ height: 8 }} />
          <Alert
            style={{ width: "100%" }}
            severity="error"
            onClose={() => setError(null)}
          >
            <p style={{ fontWeight: "600", fontSize: 14 }}>Error</p>
            <span style={{ fontSize: 13 }}>{error}</span>
          </Alert>
        </>
      )}
    </ModalWrapper>
  );
};

export default LaunchScheduledModModal;
